import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ShowPopup from '@/components/ShowPopup.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import _ from 'lodash';
import { diagnosticoStore, analyticsStore } from '@/store';
let Caixa = class Caixa extends Vue {
    constructor() {
        super(...arguments);
        this.tab = 'tab_visao_geral';
        this.loading = false;
        this.search = '';
        this.indicadoresSelected = [];
        this.selectedItem = {};
        this.updateArgs = [true, true, true];
        this.cardsDataAcoes = [];
        this.cardSelected = [];
        this.dadosPie = null;
        this.configChartAlunoPorCurso = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: 'Alunos alocados em turma com status VIGENTE- Por curso',
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b>',
            },
            accessibility: {
                point: {
                    valueSuffix: '%',
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} % <br/> {series.name}: <b>{point.y}</b>',
                    },
                },
            },
            series: [
                {
                    name: 'Alunos',
                    colorByPoint: true,
                    data: [],
                },
            ],
        };
        this.chartOptionsAlunoPorCurso = {
            title: {
                text: '',
            },
            rangeSelector: {
                selected: 4,
                inputEnabled: false,
                buttons: [
                    {
                        type: 'day',
                        count: 1,
                        text: '1 Dia',
                    },
                    {
                        type: 'week',
                        count: 1,
                        text: '1 Sem',
                    },
                    {
                        type: 'month',
                        count: 1,
                        text: '1 Mês',
                    },
                    {
                        type: 'year',
                        count: 1,
                        text: '1 Ano',
                    },
                    {
                        type: 'all',
                        count: 1,
                        text: 'Tudo',
                    },
                ],
            },
            xAxis: {
                ordinal: false,
                type: 'datetime',
                dateTimeLabelFormats: {
                    hour: '%l%P',
                    day: '%e %b',
                    week: '%e %b',
                    // @ts-ignore
                    month: '%b "%y',
                    year: '%Y',
                },
            },
            yAxis: {
                title: {
                    text: '',
                },
                plotLines: [
                    {
                        value: 0,
                        width: 1,
                        color: '#808080',
                    },
                ],
            },
            plotOptions: {
                series: {
                    showInNavigator: true,
                    marker: {
                        enabled: true,
                    },
                },
            },
            series: [
                {
                    name: '',
                    data: [],
                    color: '',
                },
            ],
        };
        this.configChartAlunoPorIdade = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: 'Alunos alocados em turma com status VIGENTE - Por faixa etária',
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b>',
            },
            accessibility: {
                point: {
                    valueSuffix: '%',
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} % <br/> {series.name}: <b>{point.y}</b>',
                    },
                },
            },
            series: [
                {
                    name: 'Alunos',
                    colorByPoint: true,
                    data: [],
                },
            ],
        };
        this.chartOptionsAlunoPorIdade = {
            title: {
                text: '',
            },
            rangeSelector: {
                selected: 4,
                inputEnabled: false,
                buttons: [
                    {
                        type: 'day',
                        count: 1,
                        text: '1 Dia',
                    },
                    {
                        type: 'week',
                        count: 1,
                        text: '1 Sem',
                    },
                    {
                        type: 'month',
                        count: 1,
                        text: '1 Mês',
                    },
                    {
                        type: 'year',
                        count: 1,
                        text: '1 Ano',
                    },
                    {
                        type: 'all',
                        count: 1,
                        text: 'Tudo',
                    },
                ],
            },
            xAxis: {
                ordinal: false,
                type: 'datetime',
                dateTimeLabelFormats: {
                    hour: '%l%P',
                    day: '%e %b',
                    week: '%e %b',
                    // @ts-ignore
                    month: '%b "%y',
                    year: '%Y',
                },
            },
            yAxis: {
                title: {
                    text: '',
                },
                plotLines: [
                    {
                        value: 0,
                        width: 1,
                        color: '#808080',
                    },
                ],
            },
            plotOptions: {
                series: {
                    showInNavigator: true,
                    marker: {
                        enabled: true,
                    },
                },
            },
            series: [
                {
                    name: '',
                    data: [],
                    color: '',
                },
            ],
        };
        this.headersAcoes = [
            [
                { show: true, text: 'Código da turma', value: 'codigo_referencia', type: '' },
                { show: true, text: 'Quantidade de alunos vigentes', value: 'quantidade_vigentes', type: '' },
                { show: true, text: 'Professor', value: 'professor_nome', type: '' },
                { show: true, text: 'Previsão de término', value: 'previsao_termino', type: 'timestamp' }
            ],
            [
                { show: true, text: 'Código da turma', value: 'codigo_referencia', type: '' },
                { show: true, text: 'Quantidade de atividades desatualizadas', value: 'quantidade_atividades', type: '' },
                { show: true, text: 'Professor', value: 'professor_nome', type: '' }
            ]
        ];
    }
    async buscar() {
        if (this.cardSelected.length <= 0) {
            return;
        }
        analyticsStore.setDefaultFilters({
            index: {
                type: 'string',
                value: this.cardSelected[1].index,
            },
        });
        analyticsStore.setHeaders(this.headersAcoes[this.cardSelected[1].index - 1]);
        analyticsStore.executeSearchItems();
    }
    async doSearchItems(queryIn) {
        return await diagnosticoStore.getDiagnosticoPedagogicoAcoesIndex(queryIn);
    }
    async mounted() {
        this.loading = true;
        this.cardsDataAcoes = await diagnosticoStore.getDiagnosticoPedagogicoAcoes();
        this.dadosPie = await diagnosticoStore.getDiagnosticoPedagogico();
        analyticsStore.initAnalytics();
        analyticsStore.setHeaders(this.headersAcoes[0]);
        analyticsStore.setFnSearchItems(diagnosticoStore.getDiagnosticoPedagogicoAcoesIndex);
        _.forEach(this.dadosPie.aluno_por_curso, (item) => {
            this.configChartAlunoPorCurso.series[0].data.push({
                y: item.y,
                name: item.name,
            });
        });
        // @ts-ignore
        this.dadosPie.totalAlunos = _.sumBy(this.dadosPie.aluno_por_curso, 
        // @ts-ignore
        (item) => item.y);
        _.forEach(this.dadosPie.aluno_por_idade, (item) => {
            this.configChartAlunoPorIdade.series[0].data.push({
                y: item.y,
                name: item.name,
            });
        });
        this.loading = false;
    }
};
Caixa = __decorate([
    Component({
        components: {
            ShowPopup,
            ClickableCard,
            OpenNewTabComponent,
            GenericListItemsCardComponent
        },
    })
], Caixa);
export default Caixa;
