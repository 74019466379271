import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { diagnosticoStore } from '@/store';
import ShowPopup from '@/components/ShowPopup.vue';
import _ from 'lodash';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
let Caixa = class Caixa extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.receitas = {};
        this.despesas = {};
        this.ticket_medio = { material: 0, curso: 0 };
        this.desconto_medio = { material: 0, curso: 0 };
        this.selectPeriodDate = [];
        this.inadimplenciaCursoObj = null;
        this.inadimplenciaMaterialObj = null;
        this.configChartCurso = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: '',
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b>',
            },
            accessibility: {
                point: {
                    valueSuffix: '%',
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.simplifiedName}</b>: {point.percentage:.1f} %',
                    },
                },
            },
            series: [
                {
                    name: 'Alunos',
                    colorByPoint: true,
                    data: [],
                },
            ],
        };
        this.configChartMaterial = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: '',
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b>',
            },
            accessibility: {
                point: {
                    valueSuffix: '%',
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.simplifiedName}</b>: {point.percentage:.1f} %',
                    },
                },
            },
            series: [
                {
                    name: 'Alunos',
                    colorByPoint: true,
                    data: [],
                },
            ],
        };
        this.headers = [
            { text: 'Categoria', value: 'financeiro_categoria_nome' },
            { text: 'Valor previsto', value: 'valor_pago' },
            { text: 'Valor realizado', value: 'valor_pago' },
        ];
        this.chartOptions = {
            title: {
                text: '',
            },
            rangeSelector: {
                selected: 4,
                inputEnabled: false,
                buttons: [
                    {
                        type: 'day',
                        count: 1,
                        text: '1 Dia',
                    },
                    {
                        type: 'week',
                        count: 1,
                        text: '1 Sem',
                    },
                    {
                        type: 'month',
                        count: 1,
                        text: '1 Mês',
                    },
                    {
                        type: 'year',
                        count: 1,
                        text: '1 Ano',
                    },
                    {
                        type: 'all',
                        count: 1,
                        text: 'Tudo',
                    },
                ],
            },
            xAxis: {
                ordinal: false,
                type: 'datetime',
                dateTimeLabelFormats: {
                    hour: '%l%P',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %y',
                    year: '%Y',
                },
            },
            yAxis: {
                title: {
                    text: '',
                },
                plotLines: [
                    {
                        value: 0,
                        width: 1,
                        color: '#808080',
                    },
                ],
            },
            plotOptions: {
                series: {
                    showInNavigator: true,
                    marker: {
                        enabled: true,
                    },
                },
            },
            series: [
                {
                    name: '',
                    data: [],
                    color: '',
                },
            ],
        };
    }
    get receitas_by_categoria() {
        // return _(this.items)
        return _(this.receitas)
            .groupBy('financeiro_categoria_nome')
            .map((item, id) => {
            const qntdObj = _.countBy(item, 'financeiro_categoria_nome');
            const quantidade = Object.values(qntdObj)[0];
            return {
                financeiro_categoria_nome: id,
                valor_pago: _.sumBy(item, 'valor_pago'),
                quantidade,
            };
        })
            .value();
    }
    get despesas_by_categoria() {
        // return _(this.items)
        return _(this.despesas)
            .groupBy('financeiro_categoria_nome')
            .map((item, id) => {
            const qntdObj = _.countBy(item, 'financeiro_categoria_nome');
            const quantidade = Object.values(qntdObj)[0];
            return {
                financeiro_categoria_nome: id,
                valor_pago: _.sumBy(item, 'valor_pago'),
                quantidade,
            };
        })
            .value();
    }
    async mounted() {
        const dados = await diagnosticoStore.getDiagnosticoFinanceiro();
        this.ticket_medio.material = dados.material_didatico_ticket_medio;
        this.ticket_medio.curso = dados.curso_ticket_medio;
        this.desconto_medio.material = dados.material_didatico_desconto_medio;
        this.desconto_medio.curso = dados.curso_desconto_medio;
        this.inadimplenciaCursoObj = {
            curso_inadimplente_2_parcelas_qtd: dados.curso_inadimplente_2_parcelas_qtd,
            curso_inadimplente_2_parcelas_valor: dados.curso_inadimplente_2_parcelas_valor,
            curso_inadimplente_3_4_parcelas_qtd: dados.curso_inadimplente_3_4_parcelas_qtd,
            curso_inadimplente_3_4_parcelas_valor: dados.curso_inadimplente_3_4_parcelas_valor,
            curso_inadimplente_4_mais_parcelas_qtd: dados.curso_inadimplente_4_mais_parcelas_qtd,
            curso_inadimplente_4_mais_parcelas_valor: dados.curso_inadimplente_4_mais_parcelas_valor
        };
        this.inadimplenciaMaterialObj = {
            material_inadimplente_2_parcelas_qtd: dados.material_inadimplente_2_parcelas_qtd,
            material_inadimplente_2_parcelas_valor: dados.material_inadimplente_2_parcelas_valor,
            material_inadimplente_3_4_parcelas_qtd: dados.material_inadimplente_3_4_parcelas_qtd,
            material_inadimplente_3_4_parcelas_valor: dados.material_inadimplente_3_4_parcelas_valor,
            material_inadimplente_4_mais_parcelas_qtd: dados.material_inadimplente_4_mais_parcelas_qtd,
            material_inadimplente_4_mais_parcelas_valor: dados.material_inadimplente_4_mais_parcelas_valor
        };
        this.configChartCurso.series[0].data.push({
            y: dados.curso_inadimplente_2_parcelas_qtd,
            name: 'Até 2 parcelas vencidas',
            simplifiedName: 'Até 2'
        });
        this.configChartCurso.series[0].data.push({
            y: dados.curso_inadimplente_3_4_parcelas_qtd,
            name: 'Entre 3 e 4 parcelas vencidas',
            simplifiedName: 'Entre 3 e 4'
        });
        this.configChartCurso.series[0].data.push({
            y: dados.curso_inadimplente_4_mais_parcelas_qtd,
            name: 'Acima de 4 parcelas vencidas',
            simplifiedName: 'Acima de 4'
        });
        this.configChartMaterial.series[0].data.push({
            y: dados.material_inadimplente_2_parcelas_qtd,
            name: 'Até 2 parcelas vencidas',
            simplifiedName: 'Até 2'
        });
        this.configChartMaterial.series[0].data.push({
            y: dados.material_inadimplente_3_4_parcelas_qtd,
            name: 'Entre 3 e 4 parcelas vencidas',
            simplifiedName: 'Entre 3 e 4'
        });
        this.configChartMaterial.series[0].data.push({
            y: dados.material_inadimplente_4_mais_parcelas_qtd,
            name: 'Acima de 4 parcelas vencidas',
            simplifiedName: 'Acima de 4'
        });
    }
};
Caixa = __decorate([
    Component({
        components: {
            ShowPopup,
            PeriodSelectorComponent,
        },
    })
], Caixa);
export default Caixa;
