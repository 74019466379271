import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { diagnosticoStore } from '@/store';
import ShowPopup from '@/components/ShowPopup.vue';
import _ from 'lodash';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
let Comercial = class Comercial extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.receitas = {};
        this.despesas = {};
        this.ticket_medio = { material: 0, curso: 0 };
        this.desconto_medio = { material: 0, curso: 0 };
        this.selectPeriodDate = [];
        this.cdaHeaders = [
            // @ts-ignore
            { text: this.getNomeCDA(), value: 'cda_nome' },
            { value: '2', text: 'Não atendeu' },
            { value: '14', text: 'Caixa Postal' },
            { value: '3', text: 'Agendado' },
            { value: '4', text: 'Reagendado' },
            { value: '5', text: 'Confirmado' },
            { value: '6', text: 'Desistiu' },
            { value: '15', text: 'Telefone não existe' },
            { value: '7', text: 'Sem interesse' },
            { value: '11', text: 'Ligar outro momento' },
            { value: 'ligacao_total', text: 'Total de ligações' },
            { value: 'cupom_total', text: 'Total Cupons' },
            { value: '12', text: 'Prospectado' },
            { value: '13', text: 'Matriculado' },
            { value: '13_percentual', text: 'Perc. Matricula' },
        ];
        this.parceriaHeaders = [
            { text: 'Parceria', value: 'parceria_nome' },
            { value: '2', text: 'Não atendeu' },
            { value: '14', text: 'Caixa Postal' },
            { value: '3', text: 'Agendado' },
            { value: '4', text: 'Reagendado' },
            { value: '5', text: 'Confirmado' },
            { value: '6', text: 'Desistiu' },
            { value: '15', text: 'Telefone não existe' },
            { value: '7', text: 'Sem interesse' },
            { value: '11', text: 'Ligar outro momento' },
            { value: 'ligacao_total', text: 'Total de ligações' },
            { value: 'cupom_total', text: 'Total Cupons' },
            { value: '12', text: 'Prospectado' },
            { value: '13', text: 'Matriculado' },
            { value: '13_percentual', text: 'Perc. Matricula' },
        ];
        this.dados = {};
        this.parceriaItems = [];
        this.parceriaCupomExistente = [];
        this.cdaCupomExistente = [];
    }
    get porParceria() {
        return _(this.dados.items)
            .groupBy('parceria_id')
            .filter((item, id) => Number.isInteger(parseInt(id, 0)))
            .map((item, id) => {
            const status = _.countBy(item, 'status_id');
            return {
                parceria_id: item[0].parceria_id,
                ...status,
                parceria_nome: item[0].parceria_nome,
            };
        })
            .value();
    }
    get porCDA() {
        return _(this.dados.items)
            .groupBy('cda_id')
            .filter((item, id) => Number.isInteger(parseInt(id, 0)))
            .map((item, id) => {
            const status = _.countBy(item, 'status_id');
            return {
                cda_id: item[0].cda_id,
                ...status,
                cda_nome: item[0].cda_nome,
            };
        })
            .value();
    }
    buscar() {
        this.carregaDados();
    }
    async carregaDados() {
        this.loading = true;
        this.dados = await diagnosticoStore.getDiagnosticoComercial({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
        this.parceriaCupomExistente = this.dados.por_parceria;
        this.cdaCupomExistente = this.dados.por_cda;
        this.loading = false;
    }
    async mounted() {
        // this.carregaDados();
    }
};
Comercial = __decorate([
    Component({
        components: {
            ShowPopup,
            PeriodSelectorComponent,
        },
    })
], Comercial);
export default Comercial;
